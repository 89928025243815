<template>
  <div class="song-row d-flex" style="width: 100%">
    <div class="play-col px-1 my-auto">
      <a :id="song.id" v-if="isReady" :class="`pause-play mx-lg-2 ${isComponentPlaying ? 'playing' : ''}`" @click.prevent="playPause">
        <div class="pie-timer">
          <div class="timer first-half">
            <div class="left-mask"></div>
            <div class="right-half"></div>
            <div class="left-half spinner"></div>
          </div>
          <div class="disk"></div>
        </div>
        <ion-icon name="play-sharp"></ion-icon>
        <ion-icon name="pause-sharp"></ion-icon>
      </a>
      <div v-if="!isReady" class="text-center"><img src="/images/mini-loader.gif" style="max-width:52px"></div>
    </div>
    <div class="title-col px-1 my-auto">
      <a @click.prevent="downloadSong(song)" class="title">
        <span>{{ song.title }}</span>
        <span v-for="(mood, j) in song.mood" :key="j+'title'" class="title-label d-lg-none">
          {{ mood.categoryName }},
        </span>
      </a>
    </div>

<!--    <div v-if="song && song.isSoundEffects" class="title-col px-1 my-auto">-->
<!--      <a class="title">-->
<!--        <span>{{ song.title }}</span>-->
<!--      </a>-->
<!--    </div>-->

    <div class="duration-col px-1 my-auto">
      <span>
        {{ timeFormat(song) }}
      </span>
    </div>
    <div v-if="!song.isSoundEffects" class="tempo-col px-1 my-auto">
      <span>{{ song.tempo }} bpm</span>
    </div>
    <div class="preview-col flex-grow-1 my-auto mx-lg-4">
      <div :id="`waveform-${index}`" class="waveform" style="min-width: 250px; width: 100%"></div>
    </div>
    <div v-if="this.isDownloadable()" class="download-col my-auto mx-lg-2">
      <a @click.prevent="downloadSong(song)" class="dl-link" id="download_btn">
        <span class="sr-only">Download</span>
        <download-icon size="1.5x" class="custom-class"></download-icon>
      </a>
    </div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import constant from "../../constants/constant";
import { mapGetters, mapActions } from 'vuex';
import { DownloadIcon, ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';
import { debounce } from 'lodash';

export default {
  props: {
    song: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    selectedSong: null,
    addSong: {
      type: Function,
      required: true,
    },
    addSoundEffects: {
      type: Function,
      required: true,
    },
  },
  components: {
    DownloadIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      wavesurfer: null,
      MAIN_URL: '',
      isReady: false,
      isComponentPlaying: false,
      initialPlay: true,
    };
  },
  mounted() {
    console.log('mounted songwavesurfer');
    this.MAIN_URL = constant.MAIN_URL;
    this.initWaveSurfer();
    this.debouncedInitWaveSurfer = debounce(this.initWaveSurfer, 300);
  },
  watch: {
    song: {
      handler() {
        console.log('song watch', this.song);
        this.$nextTick(() => {
          this.debouncedInitWaveSurfer();
        });
      },
      deep: true,
      immediate: true
    },
    seekTo: {
      handler(seekTo) {
        console.log('seekTo watch', seekTo);
        if (!this.loadMore && seekTo && this.song.id == this.currentSong.id && this.wavesurfer) {
          this.wavesurfer.seekTo(seekTo);
        }
      },
      immediate: true
    },
    isPlaying: {
      handler(isPlaying) {
        console.log('is playing' ,this.song?.id);
        console.log('is playing === ' ,this.currentSong?.id);
        this.checkIfSongPlaying(isPlaying);
      },
      immediate: true
    },
    currentSong: {
      handler(currentSong) {
        if(currentSong && this.song && this.wavesurfer){
          if(currentSong.id != this.song.id && this.wavesurfer.isPlaying()){
            this.isComponentPlaying = false;
            this.wavesurfer.stop();
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    checkIfSongPlaying(isPlaying){
      if (this.song && this.song.id && this.currentSong && this.currentSong.id) {
        if (this.song.id == this.currentSong.id) {
          console.trace('trace isplaying')
          console.log('isPlaying watch' + ' ' + this.currentSong?.id, isPlaying);

          this.isComponentPlaying = isPlaying;
          if(this.wavesurfer){
            if(isPlaying){
              this.wavesurfer.play();
            }else{
              this.wavesurfer.pause();
            }
          }
        }
        if (isPlaying) {
          this.$store.commit("player/SET_SHOW_STICKY_PLAYER", true);
          // document.getElementById('vinyl_record').classList.remove("animate-paused");
        } else {
          this.$store.commit("player/SET_SHOW_STICKY_PLAYER", false);
          // document.getElementById('vinyl_record').classList.add("animate-paused");
        }
      }else{
        this.isComponentPlaying = false;
      }
    },
    initWaveSurfer() {
      console.log('initWaveSurfer songwavesurfer')
      if (this.isReady) {
        return;
      }
      console.log('initWaveSurfer');
      const containerId = `#waveform-${this.index}`;
      const container = document.querySelector(containerId);
      if (container) {
        if (this.wavesurfer) {
          try {
            this.wavesurfer.destroy();
          } catch (error) {
            console.error("Error destroying WaveSurfer instance:", error);
          }
        }
        this.wavesurfer = WaveSurfer.create({
          container: containerId,
          waveColor: "#ebebeb",
          width: '100%',
          progressColor: "#ffd300",
          cursorColor: "#fff0",
          height: 50,
          pixelRatio: 1,
          responsive: true,
          backend: 'MediaElement',
          normalize: true
        });
        const track = this.song.isSoundEffects ? this.song.soundEffectsTrack : this.song.previewTrack || this.song.shortTrack;
        const peaks = this.song.isSoundEffects ? this.song.soundEffectsPeaks : this.song.peaksPreview || this.song.peaks;

        if (peaks) {
          this.wavesurfer.load(this.MAIN_URL + '/tracks/' + track, JSON.parse(peaks));
        } else {
          this.wavesurfer.load(this.MAIN_URL + '/tracks/' + track);
        }

        this.wavesurfer.on('ready', () => {
          this.wavesurfer.setVolume(0);
          this.$store.commit("player/SET_LOADED_SONGS", this.loadedSongs + 1);
          this.song.isReady = true;
          this.isReady = true;
          this.$store.commit("player/UPDATE_SONGS_TIME", this.song);
          console.log(`WaveSurfer ${this.index} is ready`, this.isReady);

          //this will adjust if song playing exists on the playlist
          if(this.currentSong && this.song){
            if(this.currentSong.id == this.song.id){
              // alert(this.currentSong.id);
              // alert(this.song);
              // alert(this.wavesurfer);

              // this.playPause();
              this.wavesurfer.play();
              this.wavesurfer.seekTo(this.seekTo);
              this.moveTime(this.wavesurfer)
            }
          }

        });
      } else {
        console.error(`Container not found: ${containerId}`);
      }
    },
    playPause() {
      // alert('play pause')
      // this.wavesurfer.playPause();
      // if(this.currentWavesurfer){
      //   this.currentWavesurfer.playPause();
      // }
      // this.$store.commit("player/SET_IS_PLAYING", false);
      if ((this.index + 4) > this.songs.length) {
        if (location.pathname == '/soundeffects') {
          this.addSoundEffects();
        } else {
          this.addSong();
        }
      }

      if (this.song && this.currentSong && this.song.id != this.currentSong.id) {
        // document.getElementById(this.currentSong.id).classList.remove("playing");
        this.wavesurfer.stop();
      }

      if (this.wavesurfer) {
        this.$store.commit("player/SET_SELECTED_SONG", this.song);
        this.$store.commit("player/SET_CURRENT_INDEX", this.index);

        if(this.wavesurfer){
          if(this.wavesurfer.isPlaying()){
            this.wavesurfer.stop();
          }else{
            this.wavesurfer.play();
          }
        }

        if(this.currentWavesurfer){
          if(this.currentWavesurfer.isPlaying()){
            this.currentWavesurfer.stop();
          }else{
            this.currentWavesurfer.play();
          }
        }

        // this.wavesurfer.playPause();
        // if(this.currentWavesurfer){
        //   this.currentWavesurfer.playPause();
        // }

        this.checkIfSongPlaying(this.wavesurfer.isPlaying());
        // this.$store.commit("player/SET_WAVESURFER", this.wavesurfer);

        // document.getElementById('vinyl_record').classList.add("animate-paused");
        // this.wavesurfer.playPause();
        // alert(this.wavesurfer);
        // alert(this.currentWavesurfer);

        if (this.wavesurfer.isPlaying()) {
          // this.isComponentPlaying = true;
          // document.getElementById('vinyl_record').classList.add("playing");
          // document.getElementById('vinyl_record').classList.remove("animate-paused");
          // this.$store.commit("player/SET_SHOW_STICKY_PLAYER", true);
          this.moveTime(this.wavesurfer);
          // this.wavesurfer.setVolume(this.currentVolume);
          // this.$store.commit("player/SET_VOLUME", this.currentVolume);
          this.wavesurfer.on('finish', () => {
            this.wavesurfer.stop();
            this.isComponentPlaying = false;
            this.next();
          });
          // alert("SET_IS_PLAYING 11")
          this.$store.commit("player/SET_IS_PLAYING", true);
        } else {
          // this.isComponentPlaying = false;
          // alert("SET_IS_PLAYING 22")
          this.$store.commit("player/SET_IS_PLAYING", false);
          // this.$store.commit("player/SET_SHOW_STICKY_PLAYER", false);
        }
        this.initialPlay = false;
      }
    },
    moveTime(wavesurfer) {
      console.log('moveTime', wavesurfer);
      // let mainPlayer = document.getElementById("mainPLayer");
      // wavesurfer.on('audioprocess', () => {
      //   if (wavesurfer && wavesurfer.isPlaying()) {
      //     let totalTime = wavesurfer.getDuration();
      //     let currentTime = wavesurfer.getCurrentTime();
      //     let remainingTime = totalTime - currentTime;
      //     let spinnerAngle = (currentTime / totalTime) * 360;
      //
      //     mainPlayer.querySelector(".spinner").style.transform = "rotate(" + spinnerAngle + "deg)";
      //     if (spinnerAngle > 180) {
      //       mainPlayer.querySelector(".timer").classList.remove("first-half");
      //     } else {
      //       mainPlayer.querySelector(".timer").classList.add("first-half");
      //     }
      //
      //     document.getElementById('seekTime').textContent = this.timeFormat(currentTime);
      //     document.getElementById('endTime').textContent = this.timeFormat(remainingTime);
      //   }
      // });

      wavesurfer.on('interaction', () => {
        console.log('interaction', wavesurfer);
        if (wavesurfer && wavesurfer.isPlaying()) {
          let totalTime = wavesurfer.getDuration();
          let currentTime = wavesurfer.getCurrentTime();
          let seek_to = currentTime / totalTime;
          this.$store.commit("player/SET_SEEKTO", seek_to);
        }
      });
    },
    timeStringToSeconds(timeString) {
      console.log('timeStringToSeconds', timeString);
      const [minutes, seconds] = timeString.split(':').map(Number);
      return minutes * 60 + seconds;
    },
    divideTimes(time1, time2) {
      console.log('divideTimes', time1, time2);
      const time1InSeconds = this.timeStringToSeconds(time1);
      const time2InSeconds = this.timeStringToSeconds(time2);

      if (time2InSeconds === 0) {
        throw new Error('Division by zero');
      }

      return time1InSeconds / time2InSeconds;
    },
    timeFormat(song) {
      // console.log('timeFormat', flt);
      var flt;
      // alert(song.isSoundEffect);
      if(song.isSoundEffects){
        flt = song.soundEffectsDuration ? song.soundEffectsDuration : 0;
      }else{
        flt = song.durationPreview ? song.durationPreview : (song.duration ? song.duration : 0);
      }

      var min = parseInt(flt / 60),
          sec = parseInt(flt % 60) < 10 ? "0" + parseInt(flt % 60) : parseInt(flt % 60).toString();
      var duration = min + ":" + sec;
      // alert(duration);
      return duration;
    },
    downloadSong(song) {
      console.log('xxx downloadSong' + JSON.stringify(this.song));
      if (this.isDownloadable()) {
        if (song.wavesurfer && song.wavesurfer.isPlaying()) {
          song.wavesurfer.pause();
        }
        // this.$router.push({path: "/download/" + song.slug});
        if(this.song.isSoundEffects){
          window.location.href = "/download/soundeffects/" + song.slug;
        }else{
          window.location.href = "/download/" + song.slug;
        }

      }
    },
    isDownloadable(){
      if(this.song && ((this.song.shortTrack != '' && this.song.shortTrack != null) || (this.song.longTrack != '' && this.song.longTrack != null) || this.song.isSoundEffects)){
        return true
      }return false;
    },
    next() {
      console.log('next', this.songs[this.index].title);
      if (this.songs[this.index + 1]) {
        document.getElementById(this.songs[this.index + 1].id).click();
        console.log('next+1', this.songs[this.index + 1].title);
      }
    },
  },
  computed: {
    ...mapGetters({
      songs: 'player/getSongs',
      currentWavesurfer: 'player/getCurrentWaveSurfer',
      currentSong: 'player/getSelectedSong',
      seekTo: 'player/getSeekTo',
      currentVolume: 'player/getVolume',
      loadMore: 'player/getLoadMore',
      isPlaying: 'player/getIsPlaying',
    }),
  }
};
</script>

<style>
/* Add any necessary styles here */
</style>
